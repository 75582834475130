import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import useAuth from "./hooks/useAuth";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import DashboardLayout from "./components/DashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import NewTablePage from "./pages/NewTablePage";
import TablePage from "./pages/TablePage";
import Loader from "./components/Loader";
import { Box, Link, Typography } from "@mui/material";
import jaLogo from "./assets/Logo-JuntaAndalucia-horizontal_ppal@4x.png";
import mcLogo from "./assets/Madre Coraje azul h.png";

function App() {
  const { user, loading } = useAuth();

  return loading ? (
    <Loader />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="registro" element={<RegisterPage />} />
        </Route>
        <Route
          element={
            <DashboardLayout
              isAllowed={!!user?.emailVerified}
              redirectPath="login"
            />
          }
        >
          <Route path="/" element={<DashboardPage />} />
          <Route path="rubrica/nueva" element={<NewTablePage />} />
          <Route path="rubrica/:id" element={<TablePage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        paddingY={2}
        gap={2}
        sx={{ backgroundColor: "white" }}
      >
        <Typography variant="caption">Una web de</Typography>
        <Link
          href="https://www.madrecoraje.org"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <Box height={40} sx={{ objectFit: "contain" }}>
            <img src={mcLogo} alt="Logotipo Madre Coraje" height="100%" />
          </Box>
        </Link>
        <Typography variant="caption">con el apoyo de</Typography>
        <Link
          href="http://www.juntadeandalucia.es/aacid"
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <Box height={60} sx={{ objectFit: "contain" }}>
            <img
              src={jaLogo}
              alt="Logotipos Agencia Andaluza de Cooperación Internacional para el Desarrollo"
              height="100%"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default App;
