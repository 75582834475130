import {
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  sendEmailVerification as _sendEmailVerification,
  onAuthStateChanged as _onAuthStateChanged,
} from "firebase/auth";

import { auth } from "./app";

export function onAuthStateChanged(cb) {
  return _onAuthStateChanged(auth, cb);
}

export async function signInWithEmailAndPassword(
  email: string,
  password: string,
) {
  const { user } = await _signInWithEmailAndPassword(auth, email, password);

  if (!user.emailVerified) {
    throw new Error("Email no verificado");
  }
}

export async function createUserWithEmailAndPassword(
  email: string,
  password: string,
) {
  const { user } = await _createUserWithEmailAndPassword(auth, email, password);
  await _sendEmailVerification(
    user,
    process.env.REACT_APP_PUBLIC_URL
      ? {
          url: process.env.REACT_APP_PUBLIC_URL,
        }
      : undefined,
  );
  signOut();
}

export async function signOut() {
  return auth.signOut();
}

export async function sendEmailVerification() {
  const user = auth.currentUser;
  if (user) {
    await _sendEmailVerification(
      user,
      process.env.REACT_APP_PUBLIC_URL
        ? {
            url: process.env.REACT_APP_PUBLIC_URL,
          }
        : undefined,
    );
  }
}
