import { Menu, MenuItem } from "@mui/material";

type TableMenuProps = {
  onDelete: () => void;
  onDownload: () => void;
  onClone: () => void;
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
};

const TableMenu = ({
  onDelete,
  onDownload,
  onClone,
  isOpen,
  onClose,
  anchorEl,
}: TableMenuProps) => (
  <Menu id="basic-menu" anchorEl={anchorEl} open={isOpen} onClose={onClose}>
    <MenuItem onClick={onDelete}>Eliminar</MenuItem>
    <MenuItem onClick={onDownload}>Exportar rúbrica</MenuItem>
    <MenuItem onClick={onClone}>Copiar rúbrica</MenuItem>
  </Menu>
);

export default TableMenu;
