import { useEffect, useState } from "react";
import { Box, Button, Input, Link, Typography } from "@mui/material";

import AuthCard from "../components/AuthCard";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "../lib/firebase/auth";
import { useNavigate } from "react-router-dom";
import { getReadableFirebaseMessage } from "../lib/helpers/firebase";
import useAuth from "../hooks/useAuth";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [pendingVerification, setPendingVerification] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.emailVerified) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (error === "Email no verificado") {
      setPendingVerification(true);
    } else {
      setPendingVerification(false);
    }
  }, [error]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(email, password);
      navigate("/");
    } catch (err: any) {
      setError(getReadableFirebaseMessage(err.code) || err.message);
    }
  };

  const handleResendEmailVerification = async () => {
    await sendEmailVerification();
    setPendingVerification(false);
    setError("");
  };

  const isSubmitButtonDisabled = !email || !password;

  return (
    <AuthCard>
      <Box sx={{ mb: 3 }}></Box>
      <form onSubmit={handleLogin}>
        <Input
          sx={{ mb: 3 }}
          type="email"
          placeholder="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          sx={{ mb: 3 }}
          type="password"
          placeholder="Contraseña"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          sx={{ mb: 1 }}
          fullWidth
          variant="contained"
          disabled={isSubmitButtonDisabled}
        >
          Iniciar sesión
        </Button>
      </form>
      {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      {pendingVerification && (
        <Link
          onClick={handleResendEmailVerification}
          sx={{ cursor: "pointer" }}
          paddingBottom={1}
        >
          Volver a enviar email de verificación
        </Link>
      )}
      <Typography>¿Aún no tienes cuenta?</Typography>
      <Link onClick={() => navigate("/registro")} sx={{ cursor: "pointer" }}>
        Crear una cuenta
      </Link>
    </AuthCard>
  );
};

export default LoginPage;
