import { useTheme } from "@mui/material/styles";
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { saveTable } from "../lib/firebase/firestore";
import { IndicatorEntry, Rubric, Table, Tables } from "../lib/model/Table";
import { UserDataContext } from "../context/userData";
import IndicatorFilterList from "../components/IndicatorFilterList";
import IndicatorCard from "../components/IndicatorCard";
import { AppDataContext } from "../context/appData";
import { Link, useParams } from "react-router-dom";
import { exportTable } from "../lib/helpers/dataExport";

const TablePage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const tables: Tables = useContext(UserDataContext);
  const { setFeedback } = useContext(AppDataContext);
  const [table, setTable] = useState<Table>(tables[id!]);
  const { user } = useAuth();
  const [addingIndicator, setAddingIndicator] = useState(false);
  const [changed, setChanged] = useState(false);
  const [shouldCardInputsClose, setShouldCardInputsClose] = useState(false);

  const handleSave = async () => {
    try {
      await saveTable(user!.uid!, table);
      setFeedback({
        message: "Tabla actualizada",
        type: "success",
      });

      setShouldCardInputsClose(true);
      setAddingIndicator(false);
    } catch {
      setFeedback({
        message: "Error al actualizar la rúbrica",
        type: "error",
      });
    }
  };

  const onCardInputsClosed = () => {
    setShouldCardInputsClose(false);
  };

  const handleCancel = () => {
    setTable(tables[id!]);
    setShouldCardInputsClose(true);
    setAddingIndicator(false);
  };

  const handleIndicatorClick = (indicatorEntry: IndicatorEntry) => {
    setTable({ ...table, indicators: [...table.indicators, indicatorEntry] });
  };

  const handleIndicatorDelete = (indicator: IndicatorEntry) => {
    setTable({
      ...table,
      indicators: table.indicators.filter(
        (currentIndicator) => currentIndicator.id !== indicator.id,
      ),
    });
  };

  const handleRubricAnnotationChange = (
    indicatorId: number,
    rubric: Rubric,
    value: string,
  ) => {
    setTable({
      ...table,
      indicators: table.indicators.map((indicator) =>
        indicator.id === indicatorId
          ? {
              ...indicator,
              rubrics: indicator.rubrics.map((currentRubric) =>
                currentRubric.id === rubric.id
                  ? { ...currentRubric, annotation: value }
                  : currentRubric,
              ),
            }
          : indicator,
      ),
    });
  };

  useEffect(() => {
    setTable(tables[id!]);
  }, [tables, id]);

  useEffect(() => {
    setChanged(JSON.stringify(tables[id!]) !== JSON.stringify(table));
  }, [table, tables, id]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 3, py: 2, backgroundColor: theme.palette.grey[400] }}
      >
        <Box display="flex" flexDirection="column">
          <Typography>Editar rúbrica {table?.name}</Typography>
          <Link to="/dashboard">Volver a mis rúbricas</Link>
        </Box>
        <Box
          display="flex"
          gap={1}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          {table?.indicators.length > 0 && !addingIndicator && (
            <>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => exportTable(table)}
                sx={{ backgroundColor: "#adb610" }}
              >
                Exportar
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setAddingIndicator(true)}
                sx={{
                  backgroundColor: "#f59d00",
                }}
              >
                Añadir indicador
              </Button>
            </>
          )}
        </Box>
      </Box>
      {table &&
        (addingIndicator ? (
          <Box sx={{ px: 3, pt: 2, pb: 10 }}>
            <IndicatorFilterList
              currentIndicatorIds={tables[id!].indicators.map(
                (indicator) => indicator.id,
              )}
              currentlySelectedIndicatorIds={table.indicators.map(
                (indicator) => indicator.id,
              )}
              onItemClick={handleIndicatorClick}
              onItemDelete={handleIndicatorDelete}
            />
          </Box>
        ) : table.indicators.length > 0 ? (
          <Box sx={{ px: 3, pt: 2, pb: 10 }}>
            <Grid container spacing={2} sx={{ pb: 4 }}>
              {table.indicators.sort().map((indicator) => (
                <Grid key={indicator.id} size={{ xs: 12, md: 6, lg: 4 }}>
                  <IndicatorCard
                    indicator={indicator}
                    onDelete={handleIndicatorDelete}
                    onRubricAnnotationChange={handleRubricAnnotationChange}
                    closedInputs={shouldCardInputsClose}
                    onInputsClosed={onCardInputsClosed}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              py: 6,
              px: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setAddingIndicator(true)}
              sx={{
                backgroundColor: "#f59d00",
              }}
            >
              Añadir indicador
            </Button>
          </Box>
        ))}
      {(changed || addingIndicator) && (
        <Box
          display="flex"
          justifyContent="flex-end"
          gap="12px"
          sx={{
            px: 3,
            py: 2,
            backgroundColor: theme.palette.grey[400],
          }}
        >
          <Button
            variant="outlined"
            color="error"
            startIcon={<CancelIcon />}
            onClick={handleCancel}
            sx={{ px: 4 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            sx={{ px: 4 }}
            disabled={!changed}
          >
            Guardar
          </Button>
        </Box>
      )}
    </>
  );
};

export default TablePage;
