import { useEffect, useState } from "react";
import { Button, Input, Link, Typography } from "@mui/material";
import AuthCard from "../components/AuthCard";
import { createUserWithEmailAndPassword } from "../lib/firebase/auth";
import { useNavigate } from "react-router-dom";
import { getReadableFirebaseMessage } from "../lib/helpers/firebase";
import useAuth from "../hooks/useAuth";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const [pendingVerification, setPendingVerification] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.emailVerified) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleRegister = async (e: any) => {
    e.preventDefault();
    try {
      if (password !== repeatPassword) {
        throw new Error("Las contraseñas no coinciden");
      }
      await createUserWithEmailAndPassword(email, password);
      setPendingVerification(true);
    } catch (err: any) {
      setError(getReadableFirebaseMessage(err.code) || err.message);
    }
  };

  useEffect(() => {
    if (password && repeatPassword) {
      setError("");
    }
  }, [password, repeatPassword]);

  const isSubmitButtonDisabled =
    !email || !password || !repeatPassword || pendingVerification;

  return (
    <AuthCard>
      <Typography sx={{ mb: 4 }}>Crea una cuenta de usuario</Typography>
      <form onSubmit={handleRegister}>
        <Input
          sx={{ mb: 3 }}
          type="email"
          placeholder="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          sx={{ mb: 3 }}
          type="password"
          placeholder="Contraseña"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          sx={{ mb: 3 }}
          type="password"
          placeholder="Repetir contraseña"
          fullWidth
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        <Button
          type="submit"
          sx={{ mb: 1 }}
          fullWidth
          variant="contained"
          disabled={isSubmitButtonDisabled}
        >
          Registrarse
        </Button>
      </form>
      {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      {pendingVerification && (
        <Typography sx={{ color: "#e5461b" }}>
          Se ha enviado un email de verificación
        </Typography>
      )}
      <Typography>¿Ya dispones de una cuenta?</Typography>
      <Link onClick={() => navigate("/login")} sx={{ cursor: "pointer" }}>
        Iniciar sesión
      </Link>
    </AuthCard>
  );
};

export default RegisterPage;
