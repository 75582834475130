import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Input, Typography } from "@mui/material";

import useAuth from "../hooks/useAuth";
import { saveTable } from "../lib/firebase/firestore";
import { Table } from "../lib/model/Table";
import { AppDataContext } from "../context/appData";
import { CustomError } from "../lib/model/CustomError";
import { UserDataContext } from "../context/userData";
import { useNavigate, useSearchParams } from "react-router-dom";

const NewTablePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { setFeedback } = useContext(AppDataContext);
  const tables = useContext(UserDataContext);
  const [table, setTable] = useState<Table>({
    id: uuidv4(),
    name: "",
    indicators: [],
  });
  const { user } = useAuth();

  const handleSave = async () => {
    try {
      await saveTable(user!.uid!, table);
      setFeedback({
        message: `Tabla ${
          searchParams.get("clonar") ? "clonada" : "creada"
        } con éxito`,
        type: "success",
      });

      navigate(`/rubrica/${table.id}`);
    } catch (error) {
      if (error instanceof CustomError) {
        setFeedback({
          message: error.message,
          type: "error",
        });
      } else {
        setFeedback({
          message: "Error al crear la rúbrica",
          type: "error",
        });
      }
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleNameChange = (event) => {
    setTable({ ...table, name: event.target.value });
  };

  useEffect(() => {
    const originalId = searchParams.get("clonar");
    if (originalId) {
      setTable({
        ...tables[originalId],
        id: uuidv4(),
        name: "",
      });
    }
  }, [searchParams, tables]);

  return (
    <>
      <Box sx={{ px: 3, py: 2, backgroundColor: "#cfcfcf" }}>
        <Typography>Crear rúbrica</Typography>
      </Box>
      <Box sx={{ px: 3, py: 2, flexGrow: 1 }}>
        <Typography sx={{ mb: 3 }}>
          Formulario de creación de rúbrica
        </Typography>
        <Input
          fullWidth
          sx={{ mb: 4 }}
          placeholder="Nombre de la rúbrica"
          value={table.name}
          onChange={handleNameChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        gap="12px"
        sx={{
          px: 3,
          py: 2,
          backgroundColor: theme.palette.grey[400],
        }}
      >
        <Button
          variant="outlined"
          color="error"
          startIcon={<CancelIcon />}
          onClick={handleCancel}
          sx={{ px: 4 }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          sx={{ px: 4 }}
          disabled={!table.name}
        >
          Guardar
        </Button>
      </Box>
    </>
  );
};

export default NewTablePage;
